import Moment from 'moment'
import { extendMoment } from 'moment-range'
import merge from 'lodash/merge'

const moment = extendMoment(Moment)

// Keep a version of moment that is not forced to UTC
moment.local = moment

// Force moment to always initialize in UTC
const momentInUTC = merge((...args) => moment.utc(...args), moment)

// Overwrite start of week to start at monday instead of sunday.
//
moment.fn._startOf = moment.fn.startOf
moment.fn.startOf = function (type, ...args) {
  if (type === 'week' || type === 'weeks') {
    type = 'isoweek'
  }

  return this._startOf(type, ...args)
}

// Overwrite end of week to end at sunday instead of saturday.
//
moment.fn._endOf = moment.fn.endOf
moment.fn.endOf = function (type, ...args) {
  if (type === 'week' || type === 'weeks') {
    type = 'isoweek'
  }

  return this._endOf(type, ...args)
}

/**
 * Converts date to a different timezone without converting it.
 *
 * @param {*} originalDate
 * @param {*} zone as accepted by Moment#utcOffset
 */
export const convertToTimezone = (originalDate, zone) => {
  let date = originalDate.clone()

  if (zone != null) {
    date = date.utcOffset(zone)
  }

  date.set('year', originalDate.year())
  date.set('month', originalDate.month())
  date.set('date', originalDate.date())
  date.set('hour', originalDate.hour())
  date.set('minute', originalDate.minute())
  date.set('second', originalDate.second())
  date.set('millisecond', originalDate.millisecond())

  return date
}

/**
 * Create a local moment, which is set to the local timezone.
 */
export const localMoment = (date, keepTime = false) => {
  if (keepTime) {
    return convertToTimezone(momentInUTC(date), moment.local().utcOffset())
  } else {
    return moment.local(date)
  }
}

/**
 * Convert the local time in the local timezone to the same value but in UTC.
 */
export const localMomentInUTC = (date) => {
  return convertToTimezone(moment.local(date), 0)
}

export default momentInUTC
