export const availableDateFormats = {
  'DD-MM-YYYY': 'dd-mm-yyyy',
  'MM-DD-YYYY': 'mm-dd-yyyy',
  'YYYY-MM-DD': 'yyyy-mm-dd'
}

export const dateFormats = {
  date: 'L',
  short: 'L LT',
  time: 'LT'
}

export const timezones = {
  'Africa/Abidjan': '(GMT+00:00) Abidjan',
  'Africa/Accra': '(GMT+00:00) Accra',
  'Africa/Addis_Ababa': '(GMT+03:00) Addis Ababa',
  'Africa/Algiers': '(GMT+01:00) Algiers',
  'Africa/Asmara': '(GMT+03:00) Asmara',
  'Africa/Asmera': '(GMT+03:00) Asmera',
  'Africa/Bamako': '(GMT+00:00) Bamako',
  'Africa/Bangui': '(GMT+01:00) Bangui',
  'Africa/Banjul': '(GMT+00:00) Banjul',
  'Africa/Bissau': '(GMT+00:00) Bissau',
  'Africa/Blantyre': '(GMT+02:00) Blantyre',
  'Africa/Brazzaville': '(GMT+01:00) Brazzaville',
  'Africa/Bujumbura': '(GMT+02:00) Bujumbura',
  'Africa/Cairo': '(GMT+02:00) Cairo',
  'Africa/Casablanca': '(GMT+00:00) Casablanca',
  'Africa/Ceuta': '(GMT+01:00) Ceuta',
  'Africa/Conakry': '(GMT+00:00) Conakry',
  'Africa/Dakar': '(GMT+00:00) Dakar',
  'Africa/Dar_es_Salaam': '(GMT+03:00) Dar es Salaam',
  'Africa/Djibouti': '(GMT+03:00) Djibouti',
  'Africa/Douala': '(GMT+01:00) Douala',
  'Africa/El_Aaiun': '(GMT+00:00) El Aaiun',
  'Africa/Freetown': '(GMT+00:00) Freetown',
  'Africa/Gaborone': '(GMT+02:00) Gaborone',
  'Africa/Harare': '(GMT+02:00) Harare',
  'Africa/Johannesburg': '(GMT+02:00) Johannesburg',
  'Africa/Juba': '(GMT+02:00) Juba',
  'Africa/Kampala': '(GMT+03:00) Kampala',
  'Africa/Khartoum': '(GMT+02:00) Khartoum',
  'Africa/Kigali': '(GMT+02:00) Kigali',
  'Africa/Kinshasa': '(GMT+01:00) Kinshasa',
  'Africa/Lagos': '(GMT+01:00) Lagos',
  'Africa/Libreville': '(GMT+01:00) Libreville',
  'Africa/Lome': '(GMT+00:00) Lome',
  'Africa/Luanda': '(GMT+01:00) Luanda',
  'Africa/Lubumbashi': '(GMT+02:00) Lubumbashi',
  'Africa/Lusaka': '(GMT+02:00) Lusaka',
  'Africa/Malabo': '(GMT+01:00) Malabo',
  'Africa/Maputo': '(GMT+02:00) Maputo',
  'Africa/Maseru': '(GMT+02:00) Maseru',
  'Africa/Mbabane': '(GMT+02:00) Mbabane',
  'Africa/Mogadishu': '(GMT+03:00) Mogadishu',
  'Africa/Monrovia': '(GMT+00:00) Monrovia',
  'Africa/Nairobi': '(GMT+03:00) Nairobi',
  'Africa/Ndjamena': '(GMT+01:00) Ndjamena',
  'Africa/Niamey': '(GMT+01:00) Niamey',
  'Africa/Nouakchott': '(GMT+00:00) Nouakchott',
  'Africa/Ouagadougou': '(GMT+00:00) Ouagadougou',
  'Africa/Porto-Novo': '(GMT+01:00) Porto-Novo',
  'Africa/Sao_Tome': '(GMT+00:00) Sao Tome',
  'Africa/Timbuktu': '(GMT+00:00) Timbuktu',
  'Africa/Tripoli': '(GMT+02:00) Tripoli',
  'Africa/Tunis': '(GMT+01:00) Tunis',
  'Africa/Windhoek': '(GMT+02:00) Windhoek',
  'America/Adak': '(GMT-10:00) Adak',
  'America/Anchorage': '(GMT-09:00) Anchorage',
  'America/Anguilla': '(GMT-04:00) Anguilla',
  'America/Antigua': '(GMT-04:00) Antigua',
  'America/Araguaina': '(GMT-03:00) Araguaina',
  'America/Argentina/Buenos_Aires': '(GMT-03:00) Buenos Aires',
  'America/Argentina/Catamarca': '(GMT-03:00) Catamarca',
  'America/Argentina/ComodRivadavia': '(GMT-03:00) ComodRivadavia',
  'America/Argentina/Cordoba': '(GMT-03:00) Cordoba',
  'America/Argentina/Jujuy': '(GMT-03:00) Jujuy',
  'America/Argentina/La_Rioja': '(GMT-03:00) La Rioja',
  'America/Argentina/Mendoza': '(GMT-03:00) Mendoza',
  'America/Argentina/Rio_Gallegos': '(GMT-03:00) Rio Gallegos',
  'America/Argentina/Salta': '(GMT-03:00) Salta',
  'America/Argentina/San_Juan': '(GMT-03:00) San Juan',
  'America/Argentina/San_Luis': '(GMT-03:00) San Luis',
  'America/Argentina/Tucuman': '(GMT-03:00) Tucuman',
  'America/Argentina/Ushuaia': '(GMT-03:00) Ushuaia',
  'America/Aruba': '(GMT-04:00) Aruba',
  'America/Asuncion': '(GMT-04:00) Asuncion',
  'America/Atikokan': '(GMT-05:00) Atikokan',
  'America/Atka': '(GMT-10:00) Atka',
  'America/Bahia': '(GMT-03:00) Bahia',
  'America/Bahia_Banderas': '(GMT-06:00) Bahia Banderas',
  'America/Barbados': '(GMT-04:00) Barbados',
  'America/Belem': '(GMT-03:00) Belem',
  'America/Belize': '(GMT-06:00) Belize',
  'America/Blanc-Sablon': '(GMT-04:00) Blanc-Sablon',
  'America/Boa_Vista': '(GMT-04:00) Boa Vista',
  'America/Bogota': '(GMT-05:00) Bogota',
  'America/Boise': '(GMT-07:00) Boise',
  'America/Buenos_Aires': '(GMT-03:00) Buenos Aires',
  'America/Cambridge_Bay': '(GMT-07:00) Cambridge Bay',
  'America/Campo_Grande': '(GMT-04:00) Campo Grande',
  'America/Cancun': '(GMT-05:00) Cancun',
  'America/Caracas': '(GMT-04:00) Caracas',
  'America/Catamarca': '(GMT-03:00) Catamarca',
  'America/Cayenne': '(GMT-03:00) Cayenne',
  'America/Cayman': '(GMT-05:00) Cayman',
  'America/Chicago': '(GMT-06:00) Chicago',
  'America/Chihuahua': '(GMT-07:00) Chihuahua',
  'America/Coral_Harbour': '(GMT-05:00) Coral Harbour',
  'America/Cordoba': '(GMT-03:00) Cordoba',
  'America/Costa_Rica': '(GMT-06:00) Costa Rica',
  'America/Creston': '(GMT-07:00) Creston',
  'America/Cuiaba': '(GMT-04:00) Cuiaba',
  'America/Curacao': '(GMT-04:00) Curacao',
  'America/Danmarkshavn': '(GMT+00:00) Danmarkshavn',
  'America/Dawson': '(GMT-07:00) Dawson',
  'America/Dawson_Creek': '(GMT-07:00) Dawson Creek',
  'America/Denver': '(GMT-07:00) Denver',
  'America/Detroit': '(GMT-05:00) Detroit',
  'America/Dominica': '(GMT-04:00) Dominica',
  'America/Edmonton': '(GMT-07:00) Edmonton',
  'America/Eirunepe': '(GMT-05:00) Eirunepe',
  'America/El_Salvador': '(GMT-06:00) El Salvador',
  'America/Ensenada': '(GMT-08:00) Ensenada',
  'America/Fort_Nelson': '(GMT-07:00) Fort Nelson',
  'America/Fort_Wayne': '(GMT-05:00) Fort Wayne',
  'America/Fortaleza': '(GMT-03:00) Fortaleza',
  'America/Glace_Bay': '(GMT-04:00) Glace Bay',
  'America/Godthab': '(GMT-03:00) Godthab',
  'America/Goose_Bay': '(GMT-04:00) Goose Bay',
  'America/Grand_Turk': '(GMT-05:00) Grand Turk',
  'America/Grenada': '(GMT-04:00) Grenada',
  'America/Guadeloupe': '(GMT-04:00) Guadeloupe',
  'America/Guatemala': '(GMT-06:00) Guatemala',
  'America/Guayaquil': '(GMT-05:00) Guayaquil',
  'America/Guyana': '(GMT-04:00) Guyana',
  'America/Halifax': '(GMT-04:00) Halifax',
  'America/Havana': '(GMT-05:00) Havana',
  'America/Hermosillo': '(GMT-07:00) Hermosillo',
  'America/Indiana/Indianapolis': '(GMT-05:00) Indianapolis',
  'America/Indiana/Knox': '(GMT-06:00) Knox',
  'America/Indiana/Marengo': '(GMT-05:00) Marengo',
  'America/Indiana/Petersburg': '(GMT-05:00) Petersburg',
  'America/Indiana/Tell_City': '(GMT-06:00) Tell City',
  'America/Indiana/Vevay': '(GMT-05:00) Vevay',
  'America/Indiana/Vincennes': '(GMT-05:00) Vincennes',
  'America/Indiana/Winamac': '(GMT-05:00) Winamac',
  'America/Indianapolis': '(GMT-05:00) Indianapolis',
  'America/Inuvik': '(GMT-07:00) Inuvik',
  'America/Iqaluit': '(GMT-05:00) Iqaluit',
  'America/Jamaica': '(GMT-05:00) Jamaica',
  'America/Jujuy': '(GMT-03:00) Jujuy',
  'America/Juneau': '(GMT-09:00) Juneau',
  'America/Kentucky/Louisville': '(GMT-05:00) Louisville',
  'America/Kentucky/Monticello': '(GMT-05:00) Monticello',
  'America/Knox_IN': '(GMT-06:00) Knox IN',
  'America/Kralendijk': '(GMT-04:00) Kralendijk',
  'America/La_Paz': '(GMT-04:00) La Paz',
  'America/Lima': '(GMT-05:00) Lima',
  'America/Los_Angeles': '(GMT-08:00) Los Angeles',
  'America/Louisville': '(GMT-05:00) Louisville',
  'America/Lower_Princes': '(GMT-04:00) Lower Princes',
  'America/Maceio': '(GMT-03:00) Maceio',
  'America/Managua': '(GMT-06:00) Managua',
  'America/Manaus': '(GMT-04:00) Manaus',
  'America/Marigot': '(GMT-04:00) Marigot',
  'America/Martinique': '(GMT-04:00) Martinique',
  'America/Matamoros': '(GMT-06:00) Matamoros',
  'America/Mazatlan': '(GMT-07:00) Mazatlan',
  'America/Mendoza': '(GMT-03:00) Mendoza',
  'America/Menominee': '(GMT-06:00) Menominee',
  'America/Merida': '(GMT-06:00) Merida',
  'America/Metlakatla': '(GMT-09:00) Metlakatla',
  'America/Mexico_City': '(GMT-06:00) Mexico City',
  'America/Miquelon': '(GMT-03:00) Miquelon',
  'America/Moncton': '(GMT-04:00) Moncton',
  'America/Monterrey': '(GMT-06:00) Monterrey',
  'America/Montevideo': '(GMT-03:00) Montevideo',
  'America/Montreal': '(GMT-05:00) Montreal',
  'America/Montserrat': '(GMT-04:00) Montserrat',
  'America/Nassau': '(GMT-05:00) Nassau',
  'America/New_York': '(GMT-05:00) New York',
  'America/Nipigon': '(GMT-05:00) Nipigon',
  'America/Nome': '(GMT-09:00) Nome',
  'America/Noronha': '(GMT-02:00) Noronha',
  'America/North_Dakota/Beulah': '(GMT-06:00) Beulah',
  'America/North_Dakota/Center': '(GMT-06:00) Center',
  'America/North_Dakota/New_Salem': '(GMT-06:00) New Salem',
  'America/Nuuk': '(GMT-03:00) Nuuk',
  'America/Ojinaga': '(GMT-07:00) Ojinaga',
  'America/Panama': '(GMT-05:00) Panama',
  'America/Pangnirtung': '(GMT-05:00) Pangnirtung',
  'America/Paramaribo': '(GMT-03:00) Paramaribo',
  'America/Phoenix': '(GMT-07:00) Phoenix',
  'America/Port-au-Prince': '(GMT-05:00) Port-au-Prince',
  'America/Port_of_Spain': '(GMT-04:00) Port of Spain',
  'America/Porto_Acre': '(GMT-05:00) Porto Acre',
  'America/Porto_Velho': '(GMT-04:00) Porto Velho',
  'America/Puerto_Rico': '(GMT-04:00) Puerto Rico (US)',
  'America/Punta_Arenas': '(GMT-03:00) Punta Arenas',
  'America/Rainy_River': '(GMT-06:00) Rainy River',
  'America/Rankin_Inlet': '(GMT-06:00) Rankin Inlet',
  'America/Recife': '(GMT-03:00) Recife',
  'America/Regina': '(GMT-06:00) Regina',
  'America/Resolute': '(GMT-06:00) Resolute',
  'America/Rio_Branco': '(GMT-05:00) Rio Branco',
  'America/Rosario': '(GMT-03:00) Rosario',
  'America/Santa_Isabel': '(GMT-08:00) Santa Isabel',
  'America/Santarem': '(GMT-03:00) Santarem',
  'America/Santiago': '(GMT-04:00) Santiago',
  'America/Santo_Domingo': '(GMT-04:00) Santo Domingo',
  'America/Sao_Paulo': '(GMT-03:00) Sao Paulo',
  'America/Scoresbysund': '(GMT-01:00) Scoresbysund',
  'America/Shiprock': '(GMT-07:00) Shiprock',
  'America/Sitka': '(GMT-09:00) Sitka',
  'America/St_Barthelemy': '(GMT-04:00) St Barthelemy',
  'America/St_Johns': '(GMT-03:30) St Johns',
  'America/St_Kitts': '(GMT-04:00) St Kitts',
  'America/St_Lucia': '(GMT-04:00) St Lucia',
  'America/St_Thomas': '(GMT-04:00) St Thomas',
  'America/St_Vincent': '(GMT-04:00) St Vincent',
  'America/Swift_Current': '(GMT-06:00) Swift Current',
  'America/Tegucigalpa': '(GMT-06:00) Tegucigalpa',
  'America/Thule': '(GMT-04:00) Thule',
  'America/Thunder_Bay': '(GMT-05:00) Thunder Bay',
  'America/Tijuana': '(GMT-08:00) Tijuana',
  'America/Toronto': '(GMT-05:00) Toronto',
  'America/Tortola': '(GMT-04:00) Tortola',
  'America/Vancouver': '(GMT-08:00) Vancouver',
  'America/Virgin': '(GMT-04:00) Virgin',
  'America/Whitehorse': '(GMT-07:00) Whitehorse',
  'America/Winnipeg': '(GMT-06:00) Winnipeg',
  'America/Yakutat': '(GMT-09:00) Yakutat',
  'America/Yellowknife': '(GMT-07:00) Yellowknife',
  'Antarctica/Casey': '(GMT+11:00) Casey',
  'Antarctica/Davis': '(GMT+07:00) Davis',
  'Antarctica/DumontDUrville': '(GMT+10:00) DumontDUrville',
  'Antarctica/Macquarie': '(GMT+10:00) Macquarie',
  'Antarctica/Mawson': '(GMT+05:00) Mawson',
  'Antarctica/McMurdo': '(GMT+12:00) McMurdo',
  'Antarctica/Palmer': '(GMT-03:00) Palmer',
  'Antarctica/Rothera': '(GMT-03:00) Rothera',
  'Antarctica/South_Pole': '(GMT+12:00) South Pole',
  'Antarctica/Syowa': '(GMT+03:00) Syowa',
  'Antarctica/Troll': '(GMT+00:00) Troll',
  'Antarctica/Vostok': '(GMT+06:00) Vostok',
  'Arctic/Longyearbyen': '(GMT+01:00) Longyearbyen',
  'Asia/Aden': '(GMT+03:00) Aden',
  'Asia/Almaty': '(GMT+06:00) Almaty',
  'Asia/Amman': '(GMT+02:00) Amman',
  'Asia/Anadyr': '(GMT+12:00) Anadyr',
  'Asia/Aqtau': '(GMT+05:00) Aqtau',
  'Asia/Aqtobe': '(GMT+05:00) Aqtobe',
  'Asia/Ashgabat': '(GMT+05:00) Ashgabat',
  'Asia/Ashkhabad': '(GMT+05:00) Ashkhabad',
  'Asia/Atyrau': '(GMT+05:00) Atyrau',
  'Asia/Baghdad': '(GMT+03:00) Baghdad',
  'Asia/Bahrain': '(GMT+03:00) Bahrain',
  'Asia/Baku': '(GMT+04:00) Baku',
  'Asia/Bangkok': '(GMT+07:00) Bangkok',
  'Asia/Barnaul': '(GMT+07:00) Barnaul',
  'Asia/Beirut': '(GMT+02:00) Beirut',
  'Asia/Bishkek': '(GMT+06:00) Bishkek',
  'Asia/Brunei': '(GMT+08:00) Brunei',
  'Asia/Calcutta': '(GMT+05:30) Calcutta',
  'Asia/Chita': '(GMT+09:00) Chita',
  'Asia/Choibalsan': '(GMT+08:00) Choibalsan',
  'Asia/Chongqing': '(GMT+08:00) Chongqing',
  'Asia/Chungking': '(GMT+08:00) Chungking',
  'Asia/Colombo': '(GMT+05:30) Colombo',
  'Asia/Dacca': '(GMT+06:00) Dacca',
  'Asia/Damascus': '(GMT+02:00) Damascus',
  'Asia/Dhaka': '(GMT+06:00) Dhaka',
  'Asia/Dili': '(GMT+09:00) Dili',
  'Asia/Dubai': '(GMT+04:00) Dubai',
  'Asia/Dushanbe': '(GMT+05:00) Dushanbe',
  'Asia/Famagusta': '(GMT+02:00) Famagusta',
  'Asia/Gaza': '(GMT+02:00) Gaza',
  'Asia/Harbin': '(GMT+08:00) Harbin',
  'Asia/Hebron': '(GMT+02:00) Hebron',
  'Asia/Ho_Chi_Minh': '(GMT+07:00) Ho Chi Minh',
  'Asia/Hong_Kong': '(GMT+08:00) Hong Kong',
  'Asia/Hovd': '(GMT+07:00) Hovd',
  'Asia/Irkutsk': '(GMT+08:00) Irkutsk',
  'Asia/Istanbul': '(GMT+03:00) Istanbul',
  'Asia/Jakarta': '(GMT+07:00) Jakarta',
  'Asia/Jayapura': '(GMT+09:00) Jayapura',
  'Asia/Jerusalem': '(GMT+02:00) Jerusalem',
  'Asia/Kabul': '(GMT+04:30) Kabul',
  'Asia/Kamchatka': '(GMT+12:00) Kamchatka',
  'Asia/Karachi': '(GMT+05:00) Karachi',
  'Asia/Kashgar': '(GMT+06:00) Kashgar',
  'Asia/Kathmandu': '(GMT+05:45) Kathmandu',
  'Asia/Katmandu': '(GMT+05:45) Katmandu',
  'Asia/Khandyga': '(GMT+09:00) Khandyga',
  'Asia/Kolkata': '(GMT+05:30) Kolkata',
  'Asia/Krasnoyarsk': '(GMT+07:00) Krasnoyarsk',
  'Asia/Kuala_Lumpur': '(GMT+08:00) Kuala Lumpur',
  'Asia/Kuching': '(GMT+08:00) Kuching',
  'Asia/Kuwait': '(GMT+03:00) Kuwait',
  'Asia/Macao': '(GMT+08:00) Macao',
  'Asia/Macau': '(GMT+08:00) Macau',
  'Asia/Magadan': '(GMT+11:00) Magadan',
  'Asia/Makassar': '(GMT+08:00) Makassar',
  'Asia/Manila': '(GMT+08:00) Manila',
  'Asia/Muscat': '(GMT+04:00) Muscat',
  'Asia/Nicosia': '(GMT+02:00) Nicosia',
  'Asia/Novokuznetsk': '(GMT+07:00) Novokuznetsk',
  'Asia/Novosibirsk': '(GMT+07:00) Novosibirsk',
  'Asia/Omsk': '(GMT+06:00) Omsk',
  'Asia/Oral': '(GMT+05:00) Oral',
  'Asia/Phnom_Penh': '(GMT+07:00) Phnom Penh',
  'Asia/Pontianak': '(GMT+07:00) Pontianak',
  'Asia/Pyongyang': '(GMT+09:00) Pyongyang',
  'Asia/Qatar': '(GMT+03:00) Qatar',
  'Asia/Qostanay': '(GMT+06:00) Qostanay',
  'Asia/Qyzylorda': '(GMT+05:00) Qyzylorda',
  'Asia/Rangoon': '(GMT+06:30) Rangoon',
  'Asia/Riyadh': '(GMT+03:00) Riyadh',
  'Asia/Saigon': '(GMT+07:00) Saigon',
  'Asia/Sakhalin': '(GMT+11:00) Sakhalin',
  'Asia/Samarkand': '(GMT+05:00) Samarkand',
  'Asia/Seoul': '(GMT+09:00) Seoul',
  'Asia/Shanghai': '(GMT+08:00) Shanghai',
  'Asia/Singapore': '(GMT+08:00) Singapore',
  'Asia/Srednekolymsk': '(GMT+11:00) Srednekolymsk',
  'Asia/Taipei': '(GMT+08:00) Taipei',
  'Asia/Tashkent': '(GMT+05:00) Tashkent',
  'Asia/Tbilisi': '(GMT+04:00) Tbilisi',
  'Asia/Tehran': '(GMT+03:30) Tehran',
  'Asia/Tel_Aviv': '(GMT+02:00) Tel Aviv',
  'Asia/Thimbu': '(GMT+06:00) Thimbu',
  'Asia/Thimphu': '(GMT+06:00) Thimphu',
  'Asia/Tokyo': '(GMT+09:00) Tokyo',
  'Asia/Tomsk': '(GMT+07:00) Tomsk',
  'Asia/Ujung_Pandang': '(GMT+08:00) Ujung Pandang',
  'Asia/Ulaanbaatar': '(GMT+08:00) Ulaanbaatar',
  'Asia/Ulan_Bator': '(GMT+08:00) Ulan Bator',
  'Asia/Urumqi': '(GMT+06:00) Urumqi',
  'Asia/Ust-Nera': '(GMT+10:00) Ust-Nera',
  'Asia/Vientiane': '(GMT+07:00) Vientiane',
  'Asia/Vladivostok': '(GMT+10:00) Vladivostok',
  'Asia/Yakutsk': '(GMT+09:00) Yakutsk',
  'Asia/Yangon': '(GMT+06:30) Yangon',
  'Asia/Yekaterinburg': '(GMT+05:00) Yekaterinburg',
  'Asia/Yerevan': '(GMT+04:00) Yerevan',
  'Atlantic/Azores': '(GMT-01:00) Azores',
  'Atlantic/Bermuda': '(GMT-04:00) Bermuda',
  'Atlantic/Canary': '(GMT+00:00) Canary',
  'Atlantic/Cape_Verde': '(GMT-01:00) Cape Verde',
  'Atlantic/Faeroe': '(GMT+00:00) Faeroe',
  'Atlantic/Faroe': '(GMT+00:00) Faroe',
  'Atlantic/Jan_Mayen': '(GMT+01:00) Jan Mayen',
  'Atlantic/Madeira': '(GMT+00:00) Madeira',
  'Atlantic/Reykjavik': '(GMT+00:00) Reykjavik',
  'Atlantic/South_Georgia': '(GMT-02:00) South Georgia',
  'Atlantic/St_Helena': '(GMT+00:00) St Helena',
  'Atlantic/Stanley': '(GMT-03:00) Stanley',
  'Australia/ACT': '(GMT+10:00) ACT',
  'Australia/Adelaide': '(GMT+09:30) Adelaide',
  'Australia/Brisbane': '(GMT+10:00) Brisbane',
  'Australia/Broken_Hill': '(GMT+09:30) Broken Hill',
  'Australia/Canberra': '(GMT+10:00) Canberra',
  'Australia/Currie': '(GMT+10:00) Currie',
  'Australia/Darwin': '(GMT+09:30) Darwin',
  'Australia/Eucla': '(GMT+08:45) Eucla',
  'Australia/Hobart': '(GMT+10:00) Hobart',
  'Australia/LHI': '(GMT+10:30) LHI',
  'Australia/Lindeman': '(GMT+10:00) Lindeman',
  'Australia/Lord_Howe': '(GMT+10:30) Lord Howe',
  'Australia/Melbourne': '(GMT+10:00) Melbourne',
  'Australia/NSW': '(GMT+10:00) NSW',
  'Australia/North': '(GMT+09:30) North',
  'Australia/Perth': '(GMT+08:00) Perth',
  'Australia/Queensland': '(GMT+10:00) Queensland',
  'Australia/South': '(GMT+09:30) South',
  'Australia/Sydney': '(GMT+10:00) Sydney',
  'Australia/Tasmania': '(GMT+10:00) Tasmania',
  'Australia/Victoria': '(GMT+10:00) Victoria',
  'Australia/West': '(GMT+08:00) West',
  'Australia/Yancowinna': '(GMT+09:30) Yancowinna',
  'Brazil/Acre': '(GMT-05:00) Acre',
  'Brazil/DeNoronha': '(GMT-02:00) DeNoronha',
  'Brazil/East': '(GMT-03:00) East',
  'Brazil/West': '(GMT-04:00) West',
  'CET': '(GMT+01:00) CET',
  'CST6CDT': '(GMT-06:00) CST6CDT',
  'Canada/Atlantic': '(GMT-04:00) Atlantic',
  'Canada/Central': '(GMT-06:00) Central',
  'Canada/Eastern': '(GMT-05:00) Eastern',
  'Canada/Mountain': '(GMT-07:00) Mountain',
  'Canada/Newfoundland': '(GMT-03:30) Newfoundland',
  'Canada/Pacific': '(GMT-08:00) Pacific',
  'Canada/Saskatchewan': '(GMT-06:00) Saskatchewan',
  'Canada/Yukon': '(GMT-07:00) Yukon',
  'Chile/Continental': '(GMT-04:00) Continental',
  'Chile/EasterIsland': '(GMT-06:00) EasterIsland',
  'Cuba': '(GMT-05:00) Cuba',
  'EET': '(GMT+02:00) EET',
  'EST': '(GMT-05:00) EST',
  'EST5EDT': '(GMT-05:00) EST5EDT',
  'Egypt': '(GMT+02:00) Egypt',
  'Eire': '(GMT+00:00) Eire',
  'Etc/GMT': '(GMT+00:00) GMT',
  'Etc/GMT+0': '(GMT+00:00) GMT+0',
  'Etc/GMT+1': '(GMT-01:00) GMT+1',
  'Etc/GMT+10': '(GMT-10:00) GMT+10',
  'Etc/GMT+11': '(GMT-11:00) GMT+11',
  'Etc/GMT+12': '(GMT-12:00) GMT+12',
  'Etc/GMT+2': '(GMT-02:00) GMT+2',
  'Etc/GMT+3': '(GMT-03:00) GMT+3',
  'Etc/GMT+4': '(GMT-04:00) GMT+4',
  'Etc/GMT+5': '(GMT-05:00) GMT+5',
  'Etc/GMT+6': '(GMT-06:00) GMT+6',
  'Etc/GMT+7': '(GMT-07:00) GMT+7',
  'Etc/GMT+8': '(GMT-08:00) GMT+8',
  'Etc/GMT+9': '(GMT-09:00) GMT+9',
  'Etc/GMT-0': '(GMT+00:00) GMT-0',
  'Etc/GMT-1': '(GMT+01:00) GMT-1',
  'Etc/GMT-10': '(GMT+10:00) GMT-10',
  'Etc/GMT-11': '(GMT+11:00) GMT-11',
  'Etc/GMT-12': '(GMT+12:00) GMT-12',
  'Etc/GMT-13': '(GMT+13:00) GMT-13',
  'Etc/GMT-14': '(GMT+14:00) GMT-14',
  'Etc/GMT-2': '(GMT+02:00) GMT-2',
  'Etc/GMT-3': '(GMT+03:00) GMT-3',
  'Etc/GMT-4': '(GMT+04:00) GMT-4',
  'Etc/GMT-5': '(GMT+05:00) GMT-5',
  'Etc/GMT-6': '(GMT+06:00) GMT-6',
  'Etc/GMT-7': '(GMT+07:00) GMT-7',
  'Etc/GMT-8': '(GMT+08:00) GMT-8',
  'Etc/GMT-9': '(GMT+09:00) GMT-9',
  'Etc/GMT0': '(GMT+00:00) GMT0',
  'Etc/Greenwich': '(GMT+00:00) Greenwich',
  'Etc/UCT': '(GMT+00:00) UCT',
  'Etc/UTC': '(GMT+00:00) UTC',
  'Etc/Universal': '(GMT+00:00) Universal',
  'Etc/Zulu': '(GMT+00:00) Zulu',
  'Europe/Amsterdam': '(GMT+01:00) Amsterdam',
  'Europe/Andorra': '(GMT+01:00) Andorra',
  'Europe/Astrakhan': '(GMT+04:00) Astrakhan',
  'Europe/Athens': '(GMT+02:00) Athens',
  'Europe/Belfast': '(GMT+00:00) Belfast',
  'Europe/Belgrade': '(GMT+01:00) Belgrade',
  'Europe/Berlin': '(GMT+01:00) Berlin',
  'Europe/Bratislava': '(GMT+01:00) Bratislava',
  'Europe/Brussels': '(GMT+01:00) Brussels',
  'Europe/Bucharest': '(GMT+02:00) Bucharest',
  'Europe/Budapest': '(GMT+01:00) Budapest',
  'Europe/Busingen': '(GMT+01:00) Busingen',
  'Europe/Chisinau': '(GMT+02:00) Chisinau',
  'Europe/Copenhagen': '(GMT+01:00) Copenhagen',
  'Europe/Dublin': '(GMT+00:00) Dublin',
  'Europe/Gibraltar': '(GMT+01:00) Gibraltar',
  'Europe/Guernsey': '(GMT+00:00) Guernsey',
  'Europe/Helsinki': '(GMT+02:00) Helsinki',
  'Europe/Isle_of_Man': '(GMT+00:00) Isle of Man',
  'Europe/Istanbul': '(GMT+03:00) Istanbul',
  'Europe/Jersey': '(GMT+00:00) Jersey',
  'Europe/Kaliningrad': '(GMT+02:00) Kaliningrad',
  'Europe/Kiev': '(GMT+02:00) Kyiv',
  'Europe/Kirov': '(GMT+03:00) Kirov',
  'Europe/Lisbon': '(GMT+00:00) Lisbon',
  'Europe/Ljubljana': '(GMT+01:00) Ljubljana',
  'Europe/London': '(GMT+00:00) London',
  'Europe/Luxembourg': '(GMT+01:00) Luxembourg',
  'Europe/Madrid': '(GMT+01:00) Madrid',
  'Europe/Malta': '(GMT+01:00) Malta',
  'Europe/Mariehamn': '(GMT+02:00) Mariehamn',
  'Europe/Minsk': '(GMT+03:00) Minsk',
  'Europe/Monaco': '(GMT+01:00) Monaco',
  'Europe/Moscow': '(GMT+03:00) Moscow',
  'Europe/Nicosia': '(GMT+02:00) Nicosia',
  'Europe/Oslo': '(GMT+01:00) Oslo',
  'Europe/Paris': '(GMT+01:00) Paris',
  'Europe/Podgorica': '(GMT+01:00) Podgorica',
  'Europe/Prague': '(GMT+01:00) Prague',
  'Europe/Riga': '(GMT+02:00) Riga',
  'Europe/Rome': '(GMT+01:00) Rome',
  'Europe/Samara': '(GMT+04:00) Samara',
  'Europe/San_Marino': '(GMT+01:00) San Marino',
  'Europe/Sarajevo': '(GMT+01:00) Sarajevo',
  'Europe/Saratov': '(GMT+04:00) Saratov',
  'Europe/Simferopol': '(GMT+03:00) Simferopol',
  'Europe/Skopje': '(GMT+01:00) Skopje',
  'Europe/Sofia': '(GMT+02:00) Sofia',
  'Europe/Stockholm': '(GMT+01:00) Stockholm',
  'Europe/Tallinn': '(GMT+02:00) Tallinn',
  'Europe/Tirane': '(GMT+01:00) Tirane',
  'Europe/Tiraspol': '(GMT+02:00) Tiraspol',
  'Europe/Ulyanovsk': '(GMT+04:00) Ulyanovsk',
  'Europe/Uzhgorod': '(GMT+02:00) Uzhgorod',
  'Europe/Vaduz': '(GMT+01:00) Vaduz',
  'Europe/Vatican': '(GMT+01:00) Vatican',
  'Europe/Vienna': '(GMT+01:00) Vienna',
  'Europe/Vilnius': '(GMT+02:00) Vilnius',
  'Europe/Volgograd': '(GMT+03:00) Volgograd',
  'Europe/Warsaw': '(GMT+01:00) Warsaw',
  'Europe/Zagreb': '(GMT+01:00) Zagreb',
  'Europe/Zaporozhye': '(GMT+02:00) Zaporozhye',
  'Europe/Zurich': '(GMT+01:00) Zurich',
  'Factory': '(GMT+00:00) Factory',
  'GB': '(GMT+00:00) GB',
  'GB-Eire': '(GMT+00:00) GB-Eire',
  'GMT': '(GMT+00:00) GMT',
  'GMT+0': '(GMT+00:00) GMT+0',
  'GMT-0': '(GMT+00:00) GMT-0',
  'GMT0': '(GMT+00:00) GMT0',
  'Greenwich': '(GMT+00:00) Greenwich',
  'HST': '(GMT-10:00) HST',
  'Hongkong': '(GMT+08:00) Hongkong',
  'Iceland': '(GMT+00:00) Iceland',
  'Indian/Antananarivo': '(GMT+03:00) Antananarivo',
  'Indian/Chagos': '(GMT+06:00) Chagos',
  'Indian/Christmas': '(GMT+07:00) Christmas',
  'Indian/Cocos': '(GMT+06:30) Cocos',
  'Indian/Comoro': '(GMT+03:00) Comoro',
  'Indian/Kerguelen': '(GMT+05:00) Kerguelen',
  'Indian/Mahe': '(GMT+04:00) Mahe',
  'Indian/Maldives': '(GMT+05:00) Maldives',
  'Indian/Mauritius': '(GMT+04:00) Mauritius',
  'Indian/Mayotte': '(GMT+03:00) Mayotte',
  'Indian/Reunion': '(GMT+04:00) Reunion',
  'Iran': '(GMT+03:30) Iran',
  'Israel': '(GMT+02:00) Israel',
  'Jamaica': '(GMT-05:00) Jamaica',
  'Japan': '(GMT+09:00) Japan',
  'Kwajalein': '(GMT+12:00) Kwajalein',
  'Libya': '(GMT+02:00) Libya',
  'MET': '(GMT+01:00) MET',
  'MST': '(GMT-07:00) MST',
  'MST7MDT': '(GMT-07:00) MST7MDT',
  'Mexico/BajaNorte': '(GMT-08:00) BajaNorte',
  'Mexico/BajaSur': '(GMT-07:00) BajaSur',
  'Mexico/General': '(GMT-06:00) General',
  'NZ': '(GMT+12:00) NZ',
  'NZ-CHAT': '(GMT+12:45) NZ-CHAT',
  'Navajo': '(GMT-07:00) Navajo',
  'PRC': '(GMT+08:00) PRC',
  'PST8PDT': '(GMT-08:00) PST8PDT',
  'Pacific/Apia': '(GMT+13:00) Apia',
  'Pacific/Auckland': '(GMT+12:00) Auckland',
  'Pacific/Bougainville': '(GMT+11:00) Bougainville',
  'Pacific/Chatham': '(GMT+12:45) Chatham',
  'Pacific/Chuuk': '(GMT+10:00) Chuuk',
  'Pacific/Easter': '(GMT-06:00) Easter',
  'Pacific/Efate': '(GMT+11:00) Efate',
  'Pacific/Enderbury': '(GMT+13:00) Enderbury',
  'Pacific/Fakaofo': '(GMT+13:00) Fakaofo',
  'Pacific/Fiji': '(GMT+12:00) Fiji',
  'Pacific/Funafuti': '(GMT+12:00) Funafuti',
  'Pacific/Galapagos': '(GMT-06:00) Galapagos',
  'Pacific/Gambier': '(GMT-09:00) Gambier',
  'Pacific/Guadalcanal': '(GMT+11:00) Guadalcanal',
  'Pacific/Guam': '(GMT+10:00) Guam',
  'Pacific/Honolulu': '(GMT-10:00) Honolulu',
  'Pacific/Johnston': '(GMT-10:00) Johnston',
  'Pacific/Kanton': '(GMT+13:00) Kanton',
  'Pacific/Kiritimati': '(GMT+14:00) Kiritimati',
  'Pacific/Kosrae': '(GMT+11:00) Kosrae',
  'Pacific/Kwajalein': '(GMT+12:00) Kwajalein',
  'Pacific/Majuro': '(GMT+12:00) Majuro',
  'Pacific/Marquesas': '(GMT-09:30) Marquesas',
  'Pacific/Midway': '(GMT-11:00) Midway',
  'Pacific/Nauru': '(GMT+12:00) Nauru',
  'Pacific/Niue': '(GMT-11:00) Niue',
  'Pacific/Norfolk': '(GMT+11:00) Norfolk',
  'Pacific/Noumea': '(GMT+11:00) Noumea',
  'Pacific/Pago_Pago': '(GMT-11:00) Pago Pago',
  'Pacific/Palau': '(GMT+09:00) Palau',
  'Pacific/Pitcairn': '(GMT-08:00) Pitcairn',
  'Pacific/Pohnpei': '(GMT+11:00) Pohnpei',
  'Pacific/Ponape': '(GMT+11:00) Ponape',
  'Pacific/Port_Moresby': '(GMT+10:00) Port Moresby',
  'Pacific/Rarotonga': '(GMT-10:00) Rarotonga',
  'Pacific/Saipan': '(GMT+10:00) Saipan',
  'Pacific/Samoa': '(GMT-11:00) Samoa',
  'Pacific/Tahiti': '(GMT-10:00) Tahiti',
  'Pacific/Tarawa': '(GMT+12:00) Tarawa',
  'Pacific/Tongatapu': '(GMT+13:00) Tongatapu',
  'Pacific/Truk': '(GMT+10:00) Truk',
  'Pacific/Wake': '(GMT+12:00) Wake',
  'Pacific/Wallis': '(GMT+12:00) Wallis',
  'Pacific/Yap': '(GMT+10:00) Yap',
  'Poland': '(GMT+01:00) Poland',
  'Portugal': '(GMT+00:00) Portugal',
  'ROC': '(GMT+08:00) ROC',
  'ROK': '(GMT+09:00) ROK',
  'Singapore': '(GMT+08:00) Singapore',
  'Turkey': '(GMT+03:00) Turkey',
  'UCT': '(GMT+00:00) UCT',
  'US/Alaska': '(GMT-09:00) Alaska',
  'US/Aleutian': '(GMT-10:00) Aleutian',
  'US/Arizona': '(GMT-07:00) Arizona',
  'US/Central': '(GMT-06:00) Central',
  'US/East-Indiana': '(GMT-05:00) East-Indiana',
  'US/Eastern': '(GMT-05:00) Eastern',
  'US/Hawaii': '(GMT-10:00) Hawaii',
  'US/Indiana-Starke': '(GMT-06:00) Indiana-Starke',
  'US/Michigan': '(GMT-05:00) Michigan',
  'US/Mountain': '(GMT-07:00) Mountain',
  'US/Pacific': '(GMT-08:00) Pacific',
  'US/Samoa': '(GMT-11:00) Samoa',
  'UTC': '(GMT+00:00) UTC',
  'Universal': '(GMT+00:00) Universal',
  'W-SU': '(GMT+03:00) W-SU',
  'WET': '(GMT+00:00) WET',
  'Zulu': '(GMT+00:00) Zulu'
}
