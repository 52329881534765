import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { buildYupLocale } from './yup'
import capitalize from 'lodash/capitalize'

const i18Options = {
  // Backend
  backend: {
    loadPath: () => {
      const path = '/locales/{{lng}}/{{ns}}.json'

      if (window.booqableOptions) {
        return window.booqableOptions.apiURL + path
      } else {
        return path
      }
    }
  },

  // Config
  ns: 'common',
  fallbackNS: 'common',
  // TODO: retrieve locale from the embedded shop cart/session
  lng: window.booqableOptions?.locale || window.locale,
  fallbackLng: 'en',
  debug: false,

  interpolation: {
    // not needed for react!!
    escapeValue: false
  },

  react: {
    useSuspense: false
  },

  // Return the last part of the key humanized (not in development)
  parseMissingKeyHandler: (key) => {
    // Use case for needsHumanization:
    // We can call i18next.t with an array, like t(['common.values.${variable}', 'Fallback'])
    // If the first key is not found, we can use the second key as a fallback
    // but we dont want it to be humanized (only if its a key)

    // All of our keys contain a dot (common.key, settings.key)
    // The key should not contain spaces, or end with a dot (could be a word)
    const isTranslationKey = key.indexOf(' ') === -1 && key.indexOf('.') !== -1 && !key.endsWith('.')
    const keyFallback = isTranslationKey ? capitalize(key.split('.').pop().replaceAll('_', ' ')) : key

    if (isTranslationKey && window.environment === 'development') {
      return key
    } else {
      return keyFallback
    }
  }

  // react i18next special options (optional)
  // override if needed - omit if ok with defaults
  /*
  react: {
    bindI18n: 'languageChanged',
    bindI18nStore: '',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    useSuspense: true,
  }
  */
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init(i18Options, buildYupLocale)

// Custom formatters for lowercasing and capitalizing.
// Can be used in translation files for example by using:
//
//   resource_actions:
//     add: 'Add {{resource, lowercase}}'
//
i18n.services.formatter.add('lowercase', (value, _lng, _options) => {
  return value.toLowerCase()
})
// lowercase_first_word is used in German (for example), were capitals are used in the middle of a sentence.
// For example: when the value is `Diesen Kunden`, and the desired output is: für diesen Kunden
i18n.services.formatter.add('lowercase_first_word', (value, _lng, _options) => {
  return value.charAt(0).toLowerCase() + value.slice(1)
})
i18n.services.formatter.add('capitalize', (value, _lng, _options) => {
  return value[0].toUpperCase() + value.substring(1).toLowerCase()
})

export default i18n
