import Immutable from 'immutable'
import getState from 'shared/utils/getState'

export function get (name, defaultValue, settings) {
  // No settings supplied try to get settings from global store
  if (!settings) {
    settings = getState().settings || Immutable.Map()
  }

  const value = settings.get(name)

  return value == null ? defaultValue : value
}

export function getClientVersion () {
  return getState().session.get('clientVersion')
}

export default {
  get,
  getClientVersion
}
