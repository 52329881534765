import * as Yup from 'yup'
import 'yup-phone'
import FormatUtils from 'shared/utils/format'

export function buildYupLocale (_, t) {
  Yup.setLocale({
    mixed: {
      default: ({ values }) => t('common.errors.mixed.default', { values }),
      required: ({ values }) => t('common.errors.mixed.required', { values }),
      defined: t('common.errors.mixed.defined'),
      notNull: t('common.errors.mixed.not_null'),
      oneOf: t('common.errors.mixed.one_of'),
      notOneOf: t('common.errors.mixed.not_one_of')
    },
    string: {
      length: ({ length }) => t('common.errors.string.length', { length }),
      min: ({ min }) => t('common.errors.string.min', { min }),
      max: ({ max }) => t('common.errors.string.max', { max }),
      matches: ({ regex }) => t('common.errors.string.matches', { regex }),
      phone: t('common.errors.string.phone'),
      email: t('common.errors.string.email'),
      url: t('common.errors.string.url'),
      uuid: t('common.errors.string.uuid'),
      trim: t('common.errors.string.trim'),
      lowercase: t('common.errors.string.lowercase'),
      uppercase: t('common.errors.string.uppercase')
    },
    number: {
      min: ({ min }) => t('common.errors.number.min', { min }),
      max: ({ max }) => t('common.errors.number.max', { max }),
      lessThan: ({ less }) => t('common.errors.number.less_than', { less }),
      moreThan: ({ more }) => t('common.errors.number.more_than', { more }),
      positive: t('common.errors.number.positive'),
      negative: t('common.errors.number.negative'),
      integer: t('common.errors.number.integer')
    },
    date: {
      min: ({ min }) => t('common.errors.date.min', { min: FormatUtils.date(min, 'short') }),
      max: ({ max }) => t('common.errors.date.max', { max: FormatUtils.date(max, 'short') })
    },
    object: {
      noUnknown: ({ unknown }) => t('common.errors.object.no_unknown', { unknown })
    },
    array: {
      min: ({ min }) => t('common.errors.array.min', { min }),
      max: ({ max }) => t('common.errors.array.max', { max }),
      length: ({ length }) => t('common.errors.array.length', { length })
    },
    boolean: {
      isValue: ({ value }) => t('common.errors.array.is_value', { value })
    }
  })
}

export default Yup
